<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="text-muted font-13 mb-0"></p>
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr
                            class="form-control"
                            v-model="enddate"
                            placeholder="Select Date"
                            :config="{
                              dateFormat: 'd/m/Y',
                              maxDate: new Date(),
                            }"
                            style="background-color: transparent"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button variant="primary" class="ml-2" @click="init">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
                <div id="balanceSheet">
                  <div class="overflow-auto">
                    <div class="balancesheet-table">
                      <TreeTable :value="finalData" class="w-100">
                        <Column field="name" header="Name" :expander="true"></Column>
                        <Column
                          field="amount"
                          header="Amount"
                          bodyClass="text-right"
                          headerClass="text-right"
                        ></Column>
                      </TreeTable>
                    </div>
                  </div>
                </div>
              </b-card-code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/components/axios";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import ReportTable from "../../../components/ReportTable.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import children from "./balancesheetChild.vue";

import moment from "moment";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
} from "bootstrap-vue";
import { mapState } from "vuex";

const baseApi = process.env.VUE_APP_APIENDPOINT;
import TreeTable from "primevue/treetable";
import Column from "primevue/column";
export default {
  components: {
    TreeTable,
    Column,
    children,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    ReportTable,
  },
  data() {
    return {
      data: "",
      startdate: "",
      nodes: [],
      enddate: moment().format("DD/MM/YYYY"),
      assetsLiabilityData: [],
      assets: [],
      liability: [],
      cogs: [],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      data1: [],
      DetailedOpen: {},
      incomeData: [],
      incomeTemp: [],
      expenseData: [],
      expense: [],
      income: [],
      expenseTemp: [],
      netProfit: 0,
      netLoss: 0,
      pandl: {},
      hide: false,
      finalData: [],
    };
  },
  mounted() {
    this.todate = moment().format("MM/DD/YYYY");
    this.init();
  },
  methods: {
    // handleCheckBox(e) {
    //   this.confirmledger = e.target.checked;
    //   this.confirmledgerBox = e.target.checked;
    //   this.$forceUpdate;
    // },
    // updateShowHideData(key, value) {
    //   this.$store.dispatch("app/updateShowHideData", { key, value });
    // },
    // handleLedgerCheckRec(item) {
    //   if (item?.children?.length > 0) {
    //     item &&
    //       item.children.map((item1) => {
    //         if (item1?.amount != 0) {
    //           this.obj[`${item1.name}-${item1.children?.length}`] = item1?.amount;
    //         }
    //         this.handleLedgerCheckRec(item1);
    //       });
    //   }
    // },
    // handleLedgerCheck() {
    //   this.finalData.map((item) => {
    //     item.children.map((innerItem) => {
    //       if (innerItem?.amount != 0) {
    //         this.obj[`${innerItem.name}-${innerItem.children?.length}`] =
    //           innerItem?.amount;
    //       }
    //       innerItem.children.map((item1) => {
    //         if (item1?.amount != 0) {
    //           this.obj[`${item1.name}-${item1.children?.length}`] = item1?.amount;
    //         }
    //         this.handleLedgerCheckRec(item1);
    //       });
    //     });
    //   });
    //   this.$forceUpdate();
    // },
    async init() {
      this.$store.state.app.showHideData = {};
      await this.getPandl();
      this.getBalancesheet();
    },
    // handelclickexpense(id) {
    //   let visible = this.showHideData[id] == undefined ? false : this.showHideData[id];
    //   visible = this.showHideData[id] == true ? false : true;
    //   this.updateShowHideData(id, visible);
    //   this.$forceUpdate();
    // },

    async getPandl() {
      let data = {
        enddate: moment(this.enddate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      };
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/getProfitAndLossDataYear`,
      })
        .then(async (res) => {
          this.pandl = {};
          this.pandl.total = this.roundOfDecimal(
            (res.data.data.year_amount["Income"]
              ? res.data.data.year_amount["Income"] * 1
              : 0) -
              (res.data.data.year_amount["Cost of good sold"]
                ? res.data.data.year_amount["Cost of good sold"] * 1
                : 0) -
              (res.data.data.year_amount["Expense"]
                ? res.data.data.year_amount["Expense"] * 1
                : 0),
            2
          );

          this.pandl.retain = this.roundOfDecimal(
            (res.data.data.total_amount["Income"]
              ? res.data.data.total_amount["Income"] * 1
              : 0) -
              (res.data.data.total_amount["Cost of good sold"]
                ? res.data.data.total_amount["Cost of good sold"] * 1
                : 0) -
              (res.data.data.total_amount["Expense"]
                ? res.data.data.total_amount["Expense"] * 1
                : 0),
            2
          );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    async getBalancesheet() {
      let data = {
        enddate: moment(this.enddate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      };

      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/balancesheet`,
      })
        .then(async (res) => {
          this.finalData = [];
          // this.updateShowHideData("Assets", true);
          // this.updateShowHideData("Liability", true);
          let res_group = res.data.data;
          let groups = (res_group[0].child_groups
            ? res_group[0].child_groups
            : []
          ).concat(res_group[0].child_ledgers ? res_group[0].child_ledgers : []);

          groups.forEach((ele, index) => {
            let childrens = (ele.child_groups ? ele.child_groups : []).concat(
              ele.child_ledgers ? ele.child_ledgers : []
            );
            let children1 = [];
            childrens.map((item, index) => {
              let tempData = {};
              tempData = this.recorsionFun(item, "Assets");
              item = tempData;
              // item.amount = this.roundOfDecimal(item.amount, 2);
              children1[index] = item;
            });
            let ele1 = {
              key: ele.role == "ledger" ? "ledger-" + ele.id : ele.id,
              data: {
                name: ele.name,
                amount: this.roundOfDecimal(ele.amount * -1, 2),
              },
              children: children1,
            };
            groups[index] = ele1;
          });
          this.finalData.push({
            children: groups,
            data: {
              name: "Assets",
              key: "Assets",
              amount: this.roundOfDecimal(res_group[0].amount * -1),
            },
          });

          let groups1 = (res_group[1].child_groups
            ? res_group[1].child_groups
            : []
          ).concat(res_group[1].child_ledgers ? res_group[1].child_ledgers : []);
          groups1.forEach((ele, index) => {
            let childrens = (ele.child_groups ? ele.child_groups : []).concat(
              ele.child_ledgers ? ele.child_ledgers : []
            );
            let children1 = [];

            childrens.map((item, index) => {
              let tempData = {};
              tempData = this.recorsionFun(item, "Liability");
              children1[index] = tempData;
            });

            let ele1 = {
              key: ele.role == "ledger" ? "ledger-" + ele.id : ele.id,
              data: {
                name: ele.name,
                amount: this.roundOfDecimal(ele.amount, 2),
              },
              children: children1,
            };
            groups1[index] = ele1;
          });
          groups1.push({
            key: "Retain Income",
            data: {
              name: "Retain Income",
              amount: this.roundOfDecimal(this.pandl.retain),
            },
          });
          groups1.push({
            key: "Net Income",
            data: {
              name: "Net Income",
              amount: this.roundOfDecimal(this.pandl.total),
            },
          });
          this.finalData.push({
            children: groups1,
            key: "Liabilities",
            data: {
              name: "Liabilities",
              amount:
                this.roundOfDecimal(res_group[1].amount) +
                this.roundOfDecimal(this.pandl.retain) +
                this.roundOfDecimal(this.pandl.total),
            },
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isSaving = false;
        });
    },

    // sumTotal(arr, amount) {
    //   return arr.reduce((sum, item) => sum + parseFloat(item[amount] ?? 0), 0).toFixed(2);
    // },
    recorsionFun(ele, name) {
      let children1 = [];
      let childrens = (ele.child_groups ? ele.child_groups : []).concat(
        ele.child_ledgers ? ele.child_ledgers : []
      );
      childrens.map((item, index) => {
        let tempData = {};
        tempData = this.recorsionFun(item, name);
        children1[index] = tempData;
      });
      let ele1 = {
        key: ele.role == "ledger" ? "ledger" + ele.id : ele.id,
        data: {
          name: ele.name,
          amount: ele.amount,
        },
        children: children1,
      };
      if (name == "Assets") {
        ele1.data.amount = ele1.data.amount * -1;
      }
      return ele1;
    },
  },
};
</script>

<style>
#balanceSheet {
  overflow: auto;
  display: flex;
  justify-content: center;
}
.balancesheet-table {
  min-width: 400px;
  width: 100%;
  max-width: 650px;
}
</style>
