<template>
  <tr>
    <td style="padding: 0px" colspan="4">
      <table class="table" style="color: black">
        <tbody v-for="data in data1.children" :key="data.id">
          <tr
            @click="handelclickliability(data.id, data)"
            class="cursor-pointer"
          >
            <td style="padding-left: 35px; width: 2cm" scope="col">
              <feather-icon
                v-if="
                  showHideData[data.id] &&
                  data.children &&
                  data.children.length > 0
                "
                icon="MinusIcon"
              />
              <feather-icon
                v-else-if="data.children && data.children.length > 0"
                icon="PlusIcon"
              />
            </td>
            <td style="padding-left: 55px" scope="col" class="w-10">
              {{ data.name }}
            </td>
            <td scope="col" style="text-align: end; width: 100px">
              {{ data.amount }}
            </td>
          </tr>

          <!-- <tr @click="handelclickliability(data.id)" v-else-if="confirmledger">
            <td style="padding-left: 35px" scope="col" class="w-1"></td>
            <td style="padding-left: 55px" scope="col" class="w-10">
              {{ data.code }} &#8226; {{ data.name }}
            </td>
            <td scope="col" class="w-3 text-right">{{ data.amount }}</td>
          </tr> -->
          <children
            v-if="data.children && showHideData[data.id]"
            :item="data"
          />
          <tr v-if="data.children.length > 0">
            <td style="padding-left: 40px; width: 2cm" scope="col"></td>
            <td style="padding-left: 55px; font-weight: bold" scope="col">
              Total {{ data.name }}
            </td>
            <td style="text-align: end; width: 100px; font-weight: bold">
              {{ data.amount }}
            </td>
          </tr>
        </tbody>
      </table>
    </td>
  </tr>

  <!-- <tr v-else-if="confirmledger">
    <td style="padding: 0" colspan="3">
      <table class="table table-bordered">
        <tbody v-for="data in item.children" :key="data.id">
          <tr
            @click="handelclickliability(data.id)"
            v-if="data.amount !== 0 && !confirmledger"
          >
            <td style="padding-left: 35px" scope="col" class="w-1"></td>
            <td style="padding-left: 55px" scope="col" class="w-10">
              {{ data.code }} &#8226; {{ data.name }}
            </td>
            <td scope="col" class="w-3 text-right">{{ data.amount }}</td>
          </tr>
          <tr @click="handelclickliability(data.id)" v-else-if="confirmledger">
            <td style="padding-left: 35px" scope="col" class="w-1"></td>
            <td style="padding-left: 55px" scope="col" class="w-10">
              {{ data.code }} &#8226; {{ data.name }}
            </td>
            <td scope="col" class="w-3 text-right">{{ data.amount }}</td>
          </tr>
          <children
            v-if="data.children && showHideData[data.id]"
            :item="data"
            :confirmledger="confirmledger"
          />
        </tbody>
      </table>
    </td>
  </tr> -->
</template>
<script>
export default {
  name: "children",
  props: { item: Object, confirmledger: Boolean },
  components: {
    children: this,
  },
  data() {
    return {
      showHideData: {},
      data1: [],
    };
  },
  async mounted(props) {
    console.log(this.item);
    this.data1 = this.item;

    await this.data1.children.forEach((ele, index) => {
      console.log(ele);
      let children = (ele.groups ? ele.groups : []).concat(
        ele.ledgers ? ele.ledgers : []
      );

      var amount = {
        totalamount: 0,
      };
      children.map((item) => {
        amount.totalamount += parseFloat(item.amount, 2);
      });
      if (children.length > 0) {
        // children.push({
        //   name: `Total ${ele.name}`,
        //   amount: ele.amount,
        //   id: `Total ${ele.name}`,
        //   total: true,
        // });
      }
      this.DetailedOpen = { ...this.DetailedOpen, [ele.id]: true };
      console.log(children);
      ele = {
        // total: ele.total,
        id: ele.id,
        code: ele.code,
        name: ele.name,
        groups: ele.groups,
        ledgers: ele.ledgers,
        parent: ele.parent ? ele.parent : 0,
        amount: parseFloat(ele.amount, 2),
        children: children,
        group_id: ele.group_id ? ele.group_id : 0,
        ...amount,
      };
      this.data1.children[index] = ele;
    });
  },
  methods: {
    handelclickliability(id, data) {
      console.log(data);
      this.showHideData[id] =
        this.showHideData[id] == undefined ? false : this.showHideData[id];
      console.log(this.showHideData[id], "asd");
      this.showHideData[id] = this.showHideData[id] == true ? false : true;
      console.log(this.showHideData[id], "asd");
      this.$forceUpdate();
    },
  },
};
</script>
